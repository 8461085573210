<template>
    <div class="newGoods ">
        <swiper :options="swiperOption" class="swiper-container swiper-pagination1" ref="mySwiper">
            <!-- 添加的图片 -->
            <swiper-slide v-for="item in newGoods">

                <div class="new_good_con movebig">
                    <router-link :to="{path: 'details', query: {id:item.goods_id}}" target="_blank">
                        <img class="banner-img" :src="imgPath+item.default_image" />
                        <article>{{item.goods_name}}</article>
                        <p>￥{{item.retail_price}}</p>
                        <em v-if="isShowPrice">成本价:￥{{item.gonghuojia}}</em>
                        <em v-if="!isShowPrice" @click.prevent="goLigon">登录后查看成本价</em>
                    </router-link>
                </div>
            </swiper-slide>
        </swiper>

    </div>
</template>

<script>
    require('swiper/dist/css/swiper.css');
    import {swiper, swiperSlide} from 'vue-awesome-swiper'
    import 'swiper/dist/css/swiper.css'
    import {GLdegioosstt, portalLogin} from '@/global/https'
    import {getIndex} from '@/views/homePage/home' // 获取新品接口地址
    export default {
        components: {
            swiper,
            swiperSlide
        },
        data() {
            return {
                isShowPrice: true, //是否显示成本价
                newGoods: [],
                swiperOption: {
                    pagination: '.swiper-pagination1',
                    slidesPerView: 4.5,
                    spaceBetween: 30,
                    centeredSlides: false,
                    onSlideChangeEnd: swiper => {
                        //这个位置放swiper的回调方法
                        this.page = swiper.realIndex + 1;
                        this.index = swiper.realIndex;
                    },
                    //循环
                    loop: true
                }
            }
        },
        //定义swiper对象
        computed: {
            swiper() {
                return this.$refs.mySwiper.swiper;
            }
        },
        mounted() {
            this.swiper.slideTo(0, 0, false);
            // this.getNewGoods(3); //获取swiper banner
            this.getIndex(33);
            let cookie = JSON.parse(localStorage.getItem('cookie') || '{}')
            if (cookie.tokenId) {
                // 是否显示成本价
                this.isShowPrice = true
            } else {
                this.isShowPrice = false
            }

        },
        methods: {
            async getIndex(catId, limit, order, is_nine) {
                let {code, msg, total, rows, pageNum} = await GLdegioosstt({order: 3, pageSize: 20, pageNum: 1});
                this.newGoods = rows;
            },
            goLigon() { //显示登陆弹框
                this.$store.commit('changeShowLogin', 1);
            }
        }

    }
</script>
<style lang="less" scoped>
    .newGoods {
        width: 960px;
        height: 346px;
        padding: 0 30px;
        background: #fff;
        overflow: hidden;

        .new_good_con {
            float: left;
            width: 180px;
            height: 346px;
            text-align: left;
            margin-right: 48px;

            img {
                width: 180px !important;
                height: 180px;
                padding-top: 20px;
            }

            article {
                width: 180px;
                line-height: 22px;
                padding-top: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: box;
                display: -webkit-box;
                line-clamp: 2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            p {
                color: #EC1430;
                font-size: 18px;
                padding-top: 10px;

            }

            em {
                display: block;
                font-size: 14px;
                color: #EC9314;
                padding-top: 10px;
            }
        }
    }
</style>